/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import * as React from 'react';
// import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

// import Header from './header';
import './layout.css';

interface Props {
  children: any;
}

const Layout = ({ children }: Props) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `);

  return (
    <div
      style={{
        maxWidth: 1200,
        margin: `0 auto`,
      }}
    >
      <main>{children}</main>
      <footer
        style={{
          height: `50px`,
          textAlign: `center`,
        }}
      >
        <p>© MIKAREHMAN {new Date().getFullYear()}</p>
      </footer>
    </div>
  );
};

// Layout.propTypes = {
//   children: PropTypes.node.isRequired,
// };

export default Layout;
